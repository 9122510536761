import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Product from '../components/Product';

const ProductPage = props => {
  const product = props.data.productsCsv;

  const images = (product.images || []).split(';').map(i => require(`../../assets/images/${i.trim()}`));

  return (
    <Layout title={product.name} description={product.metaDescription} keywords={product.metaKeywords}>
      <div className="c-content-box c-size-lg c-overflow-hide">
        <div className="container">
          <Product title={product.name} notice={product.notice} images={images} description={product.description} />
        </div>
      </div>
    </Layout>
  );
};

export default ProductPage;

export const query = graphql`
  query GetProduct($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    productsCsv(slug: { eq: $slug }) {
      id
      slug
      name
      notice
      images
      description
      metaDescription
      metaKeywords
    }
  }
`;
