import React, { Component } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const { title, notice, images, description } = this.props;

    const slidesToShow = images.length >= 3 ? 3 : images.length;
    const showThumbs = images.length > 1;

    return (
      <div className="c-shop-product-details-2">
        <div className="row">
          <div className="col-md-6">
            <Slider
              asNavFor={this.state.nav2}
              ref={slider => (this.slider1 = slider)}
              autoplay
              autoplaySpeed={3000}
              infinite
              fade
              arrows={false}
              pauseOnHover
              adaptiveHeight
            >
              {images.map((i, idx) => (
                <img key={idx} src={i} alt={`${title} ${idx}`} />
              ))}
            </Slider>

            {showThumbs && (
              <div id="thumbs">
                <Slider
                  className="c-margin-t-20"
                  asNavFor={this.state.nav1}
                  ref={slider => (this.slider2 = slider)}
                  slidesToShow={slidesToShow}
                  swipeToSlide
                  focusOnSelect
                  pauseOnHover
                >
                  {images.map((i, idx) => (
                    <img key={idx} src={i} alt={`${title} ${idx}`} />
                  ))}
                </Slider>
              </div>
            )}
          </div>
          <div className="col-md-offset-1 col-md-5">
            <div className="c-product-meta">
              <div className="c-content-title-1">
                <h1 className="c-font-uppercase c-font-bold c-font-white">
                  {title} {notice && <span style={{ textTransform: 'none' }}>{notice}</span>}
                </h1>
                <div className="c-line-left" />
              </div>
              <div style={{ clear: 'both' }} />
              <div className="c-product-short-desc">
                <p dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
